

/* This is one day/column in the table, and is the container or later size queries */
.emojidaycell {
    container-type: inline-size;
    align-items: start;
    justify-content: start;
}

/* Custom grid to allow 28 columns for month view */
.mygrid28 {
    grid-template-columns: repeat(28, minmax(5px, 1fr));
}

/* One date at the top of the cell.
   Once we drop enough that we're less than centered, adjust the margins to look
   better respective to the tiny surrounding container. */
.emojidatetext {
    margin-left: calc(min(5cqw, 16px));
    margin-bottom: 4px;
    font-weight: 500;
}

/* A response consists of the icon, time, and description, possibly hidden */
.emojiresponse {
    margin-bottom: 1px;
}

/* align icon with date */
.emojiicon {
    margin-left: calc(min(10cqw, 16px));
    margin-right: calc(min(10cqw, 8px));
}

/* Make the image inline and caption text flow to the right */
.emojiicon > img {
    display: inline;
    margin-bottom: 4px;
    margin-top: 4px;
}

.emojiiconcaption {
    display: none;
    display: inline-block;
    font-size: small;
    margin-left: 4px;
    vertical-align: middle;
    line-height: 105%;
}

/* Hide the caption at a pretty wide width */
@container (width < 100px) {
    .emojiiconcaption {
        display: none;
    }
}

/* Once we drop below the icon min width, hide the date and icon; show the color */
@container (width < 38px) {
    .emojidatetext {
        display: none;
    }
    .emojicolor {
        display:block;
    }
    .emojiicon {
        display:none;
    }
}

/* Hide the color div to make it mutually exclusive to the icon. */
@container (width >= 38px) {
    .emojicolor {
        display:none;
    }
}

/* Show responses horizontally if really wide (e.g. single day view) */
@container (width >= 400px) {
    .emojiresponse {
        display: inline-block;
        margin-right: 20px;
    }
}